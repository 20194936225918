/* ./src/App.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;
    --ring: 215 20.2% 65.1%;
    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply font-sans antialiased bg-background text-foreground;
  }
}

html {
	@apply h-full bg-white;
}
body {
	@apply h-full;
}

.text-xxs {
	font-size: 60%;
}
.px-0-important {
	padding-left: 0 !important;
	padding-right: 0 !important;
}
.info-section{
	@apply text-3xl font-medium text-gray-600 pb-6;
}
input[type=time]{
	@apply border-0 bg-white shadow rounded-lg text-lg px-3 py-1 focus:ring-2 focus:ring-orange-600
}

input[type=text]{
	@apply focus:ring-orange-600 focus:border-orange-600
}

input[type=number]{
	@apply focus:ring-orange-600 focus:border-orange-600
}

.slider-thumb::-webkit-slider-thumb {
    @apply appearance-none w-5 h-5 rounded-full bg-orange-500 cursor-pointer shadow;
}

.hover-area-highlight {
	[aria-label="area"] path:hover {
	  fill-opacity: 0.3 !important;
	}
	[aria-label="area"] path:hover {
	  transition: fill-opacity 0.3s;
	}
  }